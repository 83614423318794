<template>
  <div class="login-container">
    <div class="box">
      <h1>Login</h1>
      <form @submit.prevent="submit">
        <Input
          v-model="form.email.value"
          :v="form.email"
          type="text"
          label="Username"
          autocomplete="on"
        />
        <Input
          v-model="form.password.value"
          :v="form.password"
          type="password"
          label="Password"
          autocomplete="on"
        />

        <Button type="submit" color="success" :disabled="isLogging" :is-loading="isLogging"
          >Login</Button
        >
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Input from '@/app/components/base/Input';
import Button from '@/app/components/base/Button';
import { useForm } from '@/app/use/validation/index';
import { loginRequest } from '@/app/api/auth';
import { useUser } from '@/app/use/user';
import { success as showSuccessSnackbar } from '@/app/use/snackbar';

export default {
  name: 'ViewLogin',
  components: { Button, Input },
  setup() {
    const isLogging = ref(false);

    const { submit, form, useField } = useForm({
      onSubmit() {
        isLogging.value = true;
        loginRequest({
          username: form.value.email.value,
          password: form.value.password.value,
        })
          .then(response => {
            const { login } = useUser();
            login(response);
            showSuccessSnackbar('Logged in successfully');
            return response;
          })
          .catch(() => {})
          .finally(() => (isLogging.value = false));
      },
    });

    useField('password', {
      rules: {
        required: value => ({
          valid: value !== null && value.length >= 3,
          message: 'Password should be at least 3 letters long',
        }),
      },
    });

    useField('email', {
      rules: {
        required: value => ({
          valid: /\S{3,}/.test(value),
          message: 'Please provide valid username',
        }),
      },
    });

    return { submit, isLogging, form };
  },
};
</script>

<style scoped>
.login-container {
  width: 95%;
  margin: 10% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box {
  padding: 2rem;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 100%;
  max-width: 500px;
}
</style>
